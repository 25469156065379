import React from "react";
import ContentElementItem from "./ContentElementItem";

// Types
import {ContentElementItemType, InlineContentElementType} from "../../types/inlineContentElementType";
import {MediaItemType} from "../../types/inlineMedia";


// Styles
import {Button, Grid} from "@mui/material";
import {Plus} from "react-feather";


const InlineContentElement: React.FC<InlineContentElementType> = (props) => {
    const {onUpdate, onAdd, onDelete, onMediaDelete, onMediaBrowse} = props;

       // Trigger element update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    // Trigger element delete
    const onDeleteHandler = (data: MediaItemType) => {
        onDelete(data.id);
    }

    // Trigger element delete
    const onMediaDeleteHandler = (id: string) => {
        onMediaDelete(id);
    }



    const addElement = () => {
        const newElement = {
            id: new Date().getTime().toString(),
            title: '',
            description: '',
            link:'',
            label: '',
            images: [],
            video: '',
        }
        onAdd(newElement);
    }

    const onMediaBrowseHandler = (data: any) => {
        if(onMediaBrowse){
            onMediaBrowse(data);
        }

    }


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                {props.items.length > 0 &&
                    <Grid item xs={12}>
                        {props.items.map((item: ContentElementItemType) => {
                            return (
                                <ContentElementItem
                                    key={item.id}
                                    item={item}
                                    onUpdate={onUpdateHandler}
                                    onDelete={onDeleteHandler}
                                    onMediaDelete={onMediaDeleteHandler}
                                    onMediaBrowse={onMediaBrowseHandler}
                                />
                            )
                        })}
                    </Grid>
                }
                <Grid item xs={12} mb={5}>
                    <Button variant="contained" component="span" startIcon={<Plus/>} onClick={addElement}>Add element</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default InlineContentElement;
