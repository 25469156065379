import React, {ChangeEvent, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, FormControlLabel, Checkbox, FormGroup,
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import GeneralInfoCompany from "./fieldGroups/GeneralInfoCompany";
import TabPanel from "../components/TabPanel";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    useCompanyMultiEditQuery,
    useCreateCompanyMutation, useInlineMediaUploadCompanyMutation,
    useUpdateCompanyMutation, CompanyMultiEditQuery, useMultiUpdateCompanyMutation
} from "../../graphql/company/company.graphql-gen";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import AddressCompany from "./fieldGroups/AddressCompany";
import ContactInfoCompany from "./fieldGroups/ContactInfoCompany";
import AdditionalsCompany from "./fieldGroups/AdditionalsCompany";
import {
    InlineMediaUploadProductMutation,
} from "../../graphql/product/product.graphql-gen";
import {MediaItemType} from "../../types/inlineMedia";
import InlineMedia from "../../components/inline-media/InlineMedia";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    //title: Yup.string().required("Required"),
});

const CompanyMultiEdit: React.FC = () => {
    let title: string = 'Company Multi Edit';
    const model: string = 'Company';
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const inlineModels: string[] = ['logo'];
    const location: any = useLocation();

    // Queries
    const {data: data, error: error, loading: loading} = useCompanyMultiEditQuery({
        variables: {
            ids: location.state.ids,
            model,
            fields: inlineModels
        },
        onCompleted: (data: CompanyMultiEditQuery) => {
            setState({});
            setInicialValue({});

            //setUserId(data.user.user?.id);

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'logo':
                            setLogo(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [multiUpdateCompany] = useMultiUpdateCompanyMutation();
    const [inlineMediaUpload] = useInlineMediaUploadCompanyMutation();

    // States
    const [logo, setLogo] = useState<any>(dataObj.company ? dataObj.company.logo : {});
    const [logoItems, setLogoItems] = useState<any>(dataObj.company ? dataObj.company.logo.items : []);
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();

    const [userId, setUserId] = useState<any>();

    useEffect(() => {
        if (id === '') {
            setState({});
        }
    }, []);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    // Set title
    // if (dataObj.company) {
    //     title = dataObj.company.company.title;
    // }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveCompany = () => {
        updateCompanyMultiEditMutation(state);

        setInicialValue({
            "data": state, "logo": logoItems
        });
    }

    // Update mutation
    const updateCompanyMultiEditMutation = (data: any) => {
        multiUpdateCompany({
            variables: {
                model,
                ids: location.state.ids,
                data,
                logo: logoItems,
                removedMedia
            }
        })
            .then(r => {
                setUpdated(true)
                setTimeout(() => {
                    setUpdated(false);
                }, 3000)

            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }


    //Back to ListView
    const backToListView = () => {
        navigate(`/companies`, {replace: true})
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, { "data": state, "logo": logoItems});
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }
        // Update select value
        setState({...state, [fieldName]: value});

    };

    //------------------------ Inline media methods -------------------------------------

    // Add media
    const onAddLogoHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'logo',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setLogoItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onUpdateLogoHandler = (updateData: MediaItemType) => {
        const foundIndex = logoItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        logoItems[foundIndex] = updateData;
        setLogoItems(logoItems)
    }

    // Remove media
    const onRemoveLogoHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setLogoItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Company Multi Edit"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                    <Typography variant="h4" gutterBottom >
                        {dataObj.companyMultiEdit.map((item: any, index: number) => {
                            if(item.company ){
                                return item.company.title
                            }
                        }).join(', ')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveCompany}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                    <Tab label="Additional" {...tabsProps(2)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoCompany
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                    <AddressCompany
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                    <ContactInfoCompany
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Logo
                                                            </Typography>
                                                            <InlineMedia
                                                                items={logoItems}
                                                                fieldType={logo.fieldType}
                                                                allowedExtensions={logo.allowedExtensions}
                                                                isMultiple={logo.isMultiple}
                                                                onAdd={onAddLogoHandler}
                                                                onUpdate={onUpdateLogoHandler}
                                                                onDelete={onRemoveLogoHandler}/>
                                                            <FormGroup className="clear-check-top">
                                                                <FormControlLabel control={
                                                                    <Checkbox
                                                                        name="clearLogo"
                                                                        checked={state.clearLogo ? state.clearLogo : false}
                                                                        onChange={(e) =>{inputHandler(e)}}
                                                                    />
                                                                } label="Clear"/>
                                                            </FormGroup>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <AdditionalsCompany
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default CompanyMultiEdit;
