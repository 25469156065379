import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CompaniesContext} from "./store/companies-context";
import SearchBar from "../components/SearchBar";


// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit} from "@mui/icons-material";


const Divider = styled(MuiDivider)(spacing);


function Companies() {
    const companiesCtx = useContext(CompaniesContext);

    // Set table headers data
    const headCells: Array<HeadCell> = companiesCtx.headers;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Companies"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={companiesCtx.search} onSearch={companiesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Companies
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        {companiesCtx.selected && companiesCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={companiesCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >
                                Multi edit
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={companiesCtx.addItem}>
                            <AddIcon/>
                            New Company
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {companiesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={companiesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={companiesCtx.removeItem}
                    onSelectHandler={companiesCtx.selectItem}
                    onPaginateHandler={companiesCtx.onPaginate}
                    onSortHandler={companiesCtx.onSort}
                    count={companiesCtx.count}
                    page={companiesCtx.page}
                    rowsPerPage={companiesCtx.rowsPerPage}
                    filters={companiesCtx.filters}
                    onMultiEditHandler={companiesCtx.multiSelected}
                    selectedArray = {companiesCtx.selected}
                />
            </Grid>


        </React.Fragment>
    );
}

export default Companies;
