import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {PlatformsContext} from "./store/platforms-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);


function Platforms() {
    const platformsCtx = useContext(PlatformsContext);

    // Set table headers data
    const headCells: Array<HeadCell> = platformsCtx.headers;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Platforms"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={platformsCtx.search} onSearch={platformsCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Platforms
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        {platformsCtx.selected && platformsCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={platformsCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >
                                Multi edit
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={platformsCtx.addItem}>
                            <AddIcon/>
                            New Platform
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {platformsCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={platformsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={platformsCtx.removeItem}
                    onSelectHandler={platformsCtx.selectItem}
                    onPaginateHandler={platformsCtx.onPaginate}
                    onSortHandler={platformsCtx.onSort}
                    count={platformsCtx.count}
                    page={platformsCtx.page}
                    rowsPerPage={platformsCtx.rowsPerPage}
                    filters={platformsCtx.filters}
                    onMultiEditHandler={platformsCtx.multiSelected}
                    selectedArray = {platformsCtx.selected}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Platforms;
