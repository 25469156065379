import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {ProductsContext} from "./store/products-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);


function Products() {
    const productsCtx = useContext(ProductsContext);

    // Set table headers data
    const headCells: Array<HeadCell> = productsCtx.headers;

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Products"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={productsCtx.search} onSearch={productsCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Products
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        {productsCtx.selected && productsCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={productsCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >
                                Multi edit
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={productsCtx.addItem} startIcon={<AddIcon />}>
                            New Product
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {productsCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={productsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={productsCtx.removeItem}
                    onSelectHandler={productsCtx.selectItem}
                    onPaginateHandler={productsCtx.onPaginate}
                    onSortHandler={productsCtx.onSort}
                    count={productsCtx.count}
                    page={productsCtx.page}
                    rowsPerPage={productsCtx.rowsPerPage}
                    filters={productsCtx.filters}
                    onMultiEditHandler={productsCtx.multiSelected}
                    selectedArray = {productsCtx.selected}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Products;
