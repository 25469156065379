import * as Types from '../../generated/types.graphql-gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Json scalar */
  Json: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type Approval = {
  __typename?: 'Approval';
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ApprovalArray = {
  __typename?: 'ApprovalArray';
  approval?: Maybe<ApprovalData>;
  image?: Maybe<InlineMedia>;
};

export type ApprovalData = {
  __typename?: 'ApprovalData';
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Article = {
  __typename?: 'Article';
  articleNo: Scalars['String'];
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  platforms?: Maybe<Array<Maybe<Platform>>>;
  product?: Maybe<Array<Maybe<Product>>>;
  title: Scalars['String'];
};

export type ArticleArray = {
  __typename?: 'ArticleArray';
  article?: Maybe<ArticleData>;
};

export type ArticleData = {
  __typename?: 'ArticleData';
  articleNo: Scalars['String'];
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<Array<Maybe<Scalars['ID']>>>;
  title: Scalars['String'];
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  expDate?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Category = {
  __typename?: 'Category';
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Category>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type CategoryArray = {
  __typename?: 'CategoryArray';
  category?: Maybe<CategoryData>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
};

export type CategoryData = {
  __typename?: 'CategoryData';
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  additional?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<State>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyArray = {
  __typename?: 'CompanyArray';
  company?: Maybe<CompanyData>;
  logo?: Maybe<InlineMedia>;
};

export type CompanyData = {
  __typename?: 'CompanyData';
  additional?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['ID']>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type DataApproval = {
  clearImage?: InputMaybe<Scalars['Boolean']>;
  clearLink?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataArticle = {
  articleNo: Scalars['String'];
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  product?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  title: Scalars['String'];
};

export type DataCategory = {
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['ID']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type DataCompany = {
  additional?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clearAdditional?: InputMaybe<Scalars['Boolean']>;
  clearBuilding?: InputMaybe<Scalars['Boolean']>;
  clearCity?: InputMaybe<Scalars['Boolean']>;
  clearCompanySolutions?: InputMaybe<Scalars['Boolean']>;
  clearCompanyType?: InputMaybe<Scalars['Boolean']>;
  clearCountries?: InputMaybe<Scalars['Boolean']>;
  clearEmail?: InputMaybe<Scalars['Boolean']>;
  clearFax?: InputMaybe<Scalars['Boolean']>;
  clearLatitude?: InputMaybe<Scalars['Boolean']>;
  clearLogo?: InputMaybe<Scalars['Boolean']>;
  clearLongitude?: InputMaybe<Scalars['Boolean']>;
  clearMobile?: InputMaybe<Scalars['Boolean']>;
  clearPerson?: InputMaybe<Scalars['Boolean']>;
  clearPhone?: InputMaybe<Scalars['Boolean']>;
  clearStates?: InputMaybe<Scalars['Boolean']>;
  clearStreet?: InputMaybe<Scalars['Boolean']>;
  clearWebsite?: InputMaybe<Scalars['Boolean']>;
  clearZip?: InputMaybe<Scalars['Boolean']>;
  companySolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  street?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DataContentElement = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  label?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  video?: InputMaybe<Scalars['String']>;
};

export type DataFolder = {
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataMedia = {
  altTitle?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataModel = {
  fieldName?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Scalars['String']>;
  fromMediaCenter?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isMultiple?: InputMaybe<Scalars['Boolean']>;
  modelId?: InputMaybe<Scalars['ID']>;
  modelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  modelName?: InputMaybe<Scalars['String']>;
  submodelId?: InputMaybe<Scalars['String']>;
  submodelName?: InputMaybe<Scalars['String']>;
};

export type DataOption = {
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataPlatform = {
  clearCode?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataProduct = {
  accessories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvals?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvalsNote?: InputMaybe<Scalars['String']>;
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  clearAccessories?: InputMaybe<Scalars['Boolean']>;
  clearApprovals?: InputMaybe<Scalars['Boolean']>;
  clearApprovalsNote?: InputMaybe<Scalars['Boolean']>;
  clearCategories?: InputMaybe<Scalars['Boolean']>;
  clearCompanies?: InputMaybe<Scalars['Boolean']>;
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearFiles?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProductType?: InputMaybe<Scalars['Boolean']>;
  clearPublishDate?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['String']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearSupply?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecifications?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecificationsNote?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  clearVoltageFrom?: InputMaybe<Scalars['Boolean']>;
  clearVoltageTo?: InputMaybe<Scalars['Boolean']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishDate?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  supply?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  voltageFrom?: InputMaybe<Scalars['String']>;
  voltageTo?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalOption = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalSpecification = {
  id: Scalars['ID'];
  options?: InputMaybe<Array<InputMaybe<DataTechnicalOption>>>;
  sorting?: InputMaybe<Scalars['String']>;
  type: Scalars['ID'];
};

export type DataUser = {
  crUser?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type DataUserGroup = {
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  extension?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  mimeType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FileArray = {
  __typename?: 'FileArray';
  file?: Maybe<File>;
};

export type Filter = {
  fieldName?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<FilterOption>>>;
};

export type FilterOption = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Folder = {
  __typename?: 'Folder';
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FolderArray = {
  __typename?: 'FolderArray';
  errorMessage?: Maybe<Scalars['String']>;
  folder?: Maybe<Folder>;
};

export type InitInlineMedia = {
  __typename?: 'InitInlineMedia';
  field: Scalars['String'];
  inlineMedia: InlineMedia;
};

export type InlineContentElement = {
  __typename?: 'InlineContentElement';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<MediaItem>>>;
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
};

export type InlineMedia = {
  __typename?: 'InlineMedia';
  allowedExtensions?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MediaItem>>>;
};

export type InlineOptions = {
  __typename?: 'InlineOptions';
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type InlineTechnicalSpecification = {
  __typename?: 'InlineTechnicalSpecification';
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<TechnicalOption>>>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
};

export type MediaItem = {
  __typename?: 'MediaItem';
  altTitle?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApproval: ApprovalArray;
  createArticle: ArticleArray;
  createCategory: CategoryArray;
  createCompany: CompanyArray;
  createFile: Scalars['Boolean'];
  createFolder: FolderArray;
  createPlatform: PlatformArray;
  createProduct: ProductArray;
  createReference: ReferenceArray;
  createUser?: Maybe<UserArray>;
  createUserGroup: UserGroupArray;
  deleteApproval: Approval;
  deleteArticle?: Maybe<Article>;
  deleteCategory?: Maybe<Category>;
  deleteCompany: Company;
  deleteFile?: Maybe<File>;
  deleteFolder?: Maybe<Folder>;
  deletePlatform: Platform;
  deleteProduct?: Maybe<Product>;
  deleteUser?: Maybe<User>;
  deleteUserGroup: UserGroup;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  inlineMediaUpload: Array<Maybe<MediaItem>>;
  multiResetApproval: Scalars['Boolean'];
  multiResetCategory: Scalars['Boolean'];
  multiResetCompany: Scalars['Boolean'];
  multiResetProduct: Scalars['Boolean'];
  multiUpdateApproval: ApprovalArray;
  multiUpdateCategory: CategoryArray;
  multiUpdateCompany: CompanyArray;
  multiUpdatePlatform: PlatformArray;
  multiUpdateProduct: ProductArray;
  refreshAccessToken: AuthUser;
  resetApproval: Scalars['Boolean'];
  resetCategory: Scalars['Boolean'];
  resetCompany: Scalars['Boolean'];
  resetPassword?: Maybe<Scalars['Boolean']>;
  resetProduct: Scalars['Boolean'];
  resetProfilePassword?: Maybe<ResetRespos>;
  signIn: AuthUser;
  signOut?: Maybe<Scalars['Boolean']>;
  updateApproval: ApprovalArray;
  updateArticle: ArticleArray;
  updateCategory: CategoryArray;
  updateCompany: CompanyArray;
  updateFolder: FolderArray;
  updatePlatform: PlatformArray;
  updateProduct: ProductArray;
  updateSelectField: SelectFieldArray;
  updateUser?: Maybe<UserArray>;
  updateUserGroup: UserGroupArray;
};


export type MutationCreateApprovalArgs = {
  data: DataApproval;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateArticleArgs = {
  data: DataArticle;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  data: DataCompany;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateFileArgs = {
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  parent?: InputMaybe<Scalars['ID']>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateFolderArgs = {
  data: DataFolder;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationCreatePlatformArgs = {
  data: DataPlatform;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateReferenceArgs = {
  dataModel?: InputMaybe<DataModel>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateUserArgs = {
  data: DataUser;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateUserGroupArgs = {
  data: DataUserGroup;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationDeleteApprovalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeletePlatformArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationInlineMediaUploadArgs = {
  field: Scalars['String'];
  files?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  folder?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetApprovalArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCategoryArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCompanyArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetProductArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateApprovalArgs = {
  data: DataApproval;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCompanyArgs = {
  data: DataCompany;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdatePlatformArgs = {
  data: DataPlatform;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResetApprovalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetProfilePasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateApprovalArgs = {
  data: DataApproval;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateArticleArgs = {
  data?: InputMaybe<DataArticle>;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCompanyArgs = {
  data: DataCompany;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateFolderArgs = {
  data: DataFolder;
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdatePlatformArgs = {
  data: DataPlatform;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<Array<InputMaybe<DataOption>>>;
};


export type MutationUpdateUserArgs = {
  data: DataUser;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateUserGroupArgs = {
  data: DataUserGroup;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

export type Pagination = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Platform = {
  __typename?: 'Platform';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type PlatformArray = {
  __typename?: 'PlatformArray';
  platform?: Maybe<PlatformData>;
};

export type PlatformData = {
  __typename?: 'PlatformData';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Maybe<Product>>>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Article>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  platforms?: Maybe<Array<Maybe<Platform>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type ProductArray = {
  __typename?: 'ProductArray';
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  files?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  product?: Maybe<ProductData>;
  technicalSpecifications?: Maybe<Array<Maybe<InlineTechnicalSpecification>>>;
};

export type ProductData = {
  __typename?: 'ProductData';
  accessories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cr_user?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  approval: ApprovalArray;
  approvalMultiEdit: Array<Maybe<ApprovalArray>>;
  article: ArticleArray;
  categoriesThree?: Maybe<Scalars['Json']>;
  category: CategoryArray;
  categoryMultiEdit: Array<Maybe<CategoryArray>>;
  company: CompanyArray;
  companyMultiEdit: Array<Maybe<CompanyArray>>;
  getDynamicTable?: Maybe<Scalars['Json']>;
  getFile?: Maybe<FileArray>;
  getFolder?: Maybe<FolderArray>;
  getMediacenter?: Maybe<Scalars['Json']>;
  getSelectField?: Maybe<SelectFieldArray>;
  getSelectFields?: Maybe<Array<Maybe<SelectField>>>;
  getSpecificSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getTechnicalSpecificationSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getUnitSelect?: Maybe<Array<Maybe<UnitOption>>>;
  inlineMedia?: Maybe<Array<InitInlineMedia>>;
  platform: PlatformArray;
  platformMultiEdit: Array<Maybe<PlatformArray>>;
  product: ProductArray;
  productMultiEdit: Array<Maybe<ProductArray>>;
  selectAccessories: Array<Product>;
  selectApprovals: Array<Approval>;
  selectCompanies: Array<Company>;
  selectCountries: Array<Country>;
  selectPlatforms: Array<Platform>;
  selectStates: Array<State>;
  user: UserArray;
  userGroup: UserGroupArray;
};


export type QueryApprovalArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryApprovalMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCategoriesThreeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCategoryMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  mediacenter?: InputMaybe<Scalars['Boolean']>;
  model: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCompanyMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryGetDynamicTableArgs = {
  filters?: InputMaybe<Filter>;
  model: Scalars['String'];
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Sorting>;
};


export type QueryGetFileArgs = {
  id: Scalars['ID'];
};


export type QueryGetFolderArgs = {
  id: Scalars['ID'];
};


export type QueryGetMediacenterArgs = {
  dataModel?: InputMaybe<DataModel>;
  id?: InputMaybe<Scalars['ID']>;
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSpecificSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetTechnicalSpecificationSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetUnitSelectArgs = {
  model: Scalars['String'];
};


export type QueryInlineMediaArgs = {
  fields?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPlatformArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryPlatformMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryProductMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QuerySelectAccessoriesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySelectApprovalsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCompaniesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCountriesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectPlatformsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectStatesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};

export type ReferenceArray = {
  __typename?: 'ReferenceArray';
  id?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ResetRespos = {
  __typename?: 'ResetRespos';
  error?: Maybe<Scalars['Json']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SelectField = {
  __typename?: 'SelectField';
  field: Scalars['String'];
  id: Scalars['ID'];
  model: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SelectFieldArray = {
  __typename?: 'SelectFieldArray';
  options?: Maybe<Array<Maybe<InlineOptions>>>;
  selectField?: Maybe<SelectField>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Sorting = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type TechnicalOption = {
  __typename?: 'TechnicalOption';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnitOption = {
  __typename?: 'UnitOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  crUser?: Maybe<User>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserArray = {
  __typename?: 'UserArray';
  user?: Maybe<UserData>;
};

export type UserData = {
  __typename?: 'UserData';
  crUser?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  cr_user?: Maybe<User>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UserGroupArray = {
  __typename?: 'UserGroupArray';
  images?: Maybe<InlineMedia>;
  userGroup?: Maybe<UserGroupData>;
};

export type UserGroupData = {
  __typename?: 'UserGroupData';
  crUser?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type CompanyQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'CompanyArray', company?: { __typename?: 'CompanyData', id?: string | null, title?: string | null, additional?: string | null, building?: string | null, city?: string | null, companySolutions?: Array<string | null> | null, companyType?: Array<string | null> | null, email?: string | null, fax?: string | null, countries?: Array<string | null> | null, states?: Array<string | null> | null, latitude?: string | null, longitude?: string | null, mobile?: string | null, person?: string | null, phone?: string | null, street?: string | null, website?: string | null, zip?: string | null, hidden?: boolean | null } | null, logo?: { __typename?: 'InlineMedia', isMultiple?: boolean | null, fieldType?: string | null, allowedExtensions?: string | null, items?: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> | null } | null }, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectCountries: Array<{ __typename?: 'Country', id?: string | null, title?: string | null }>, selectStates: Array<{ __typename?: 'State', id?: string | null, title?: string | null }>, user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } };

export type CompanyMultiEditQueryVariables = Types.Exact<{
  ids: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  fields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  model: Types.Scalars['String'];
}>;


export type CompanyMultiEditQuery = { __typename?: 'Query', companyMultiEdit: Array<{ __typename?: 'CompanyArray', company?: { __typename?: 'CompanyData', title?: string | null } | null } | null>, inlineMedia?: Array<{ __typename?: 'InitInlineMedia', field: string, inlineMedia: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null } }> | null, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectCountries: Array<{ __typename?: 'Country', id?: string | null, title?: string | null }>, selectStates: Array<{ __typename?: 'State', id?: string | null, title?: string | null }> };

export type NewCompanyQueryVariables = Types.Exact<{
  fields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  model: Types.Scalars['String'];
}>;


export type NewCompanyQuery = { __typename?: 'Query', inlineMedia?: Array<{ __typename?: 'InitInlineMedia', field: string, inlineMedia: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null } }> | null, getSpecificSelect?: Array<{ __typename?: 'SelectOption', id: string, title: string, field: string } | null> | null, selectCountries: Array<{ __typename?: 'Country', id?: string | null, title?: string | null }>, selectStates: Array<{ __typename?: 'State', id?: string | null, title?: string | null }>, user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } };

export type CompaniesListQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  pagination: Types.Pagination;
  sorting?: Types.InputMaybe<Types.Sorting>;
}>;


export type CompaniesListQuery = { __typename?: 'Query', getDynamicTable?: any | null };

export type UpdateCompanyMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  id: Types.Scalars['ID'];
  data: Types.DataCompany;
  logo?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  temp?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyArray', company?: { __typename?: 'CompanyData', id?: string | null } | null } };

export type MultiUpdateCompanyMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  ids: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  data: Types.DataCompany;
  logo?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type MultiUpdateCompanyMutation = { __typename?: 'Mutation', multiUpdateCompany: { __typename?: 'CompanyArray', company?: { __typename?: 'CompanyData', id?: string | null } | null } };

export type CreateCompanyMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  data: Types.DataCompany;
  user: Types.Scalars['ID'];
  logo?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  temp?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyArray', company?: { __typename?: 'CompanyData', id?: string | null } | null } };

export type InlineMediaUploadCompanyMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
  field: Types.Scalars['String'];
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Upload']>> | Types.InputMaybe<Types.Scalars['Upload']>>;
}>;


export type InlineMediaUploadCompanyMutation = { __typename?: 'Mutation', inlineMediaUpload: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> };

export type DeleteCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: { __typename?: 'Company', id: string } };

export type ResetCompanyMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
}>;


export type ResetCompanyMutation = { __typename?: 'Mutation', resetCompany: boolean };


export const CompanyDocument = gql`
    query Company($id: ID!, $model: String!) {
  company(id: $id, model: $model) {
    company {
      id
      title
      additional
      building
      city
      companySolutions
      companyType
      email
      fax
      countries
      states
      latitude
      longitude
      mobile
      person
      phone
      street
      title
      website
      zip
      hidden
    }
    logo {
      items {
        id
        fileName
        filePath
        thumbnail
        title
        altTitle
        description
        author
      }
      isMultiple
      fieldType
      allowedExtensions
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectCountries {
    id
    title
  }
  selectStates {
    id
    title
  }
  user {
    user {
      id
    }
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanyMultiEditDocument = gql`
    query CompanyMultiEdit($ids: [ID]!, $fields: [String!], $model: String!) {
  companyMultiEdit(ids: $ids, model: $model) {
    company {
      title
    }
  }
  inlineMedia(fields: $fields) {
    field
    inlineMedia {
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectCountries {
    id
    title
  }
  selectStates {
    id
    title
  }
}
    `;

/**
 * __useCompanyMultiEditQuery__
 *
 * To run a query within a React component, call `useCompanyMultiEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMultiEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMultiEditQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      fields: // value for 'fields'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCompanyMultiEditQuery(baseOptions: Apollo.QueryHookOptions<CompanyMultiEditQuery, CompanyMultiEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMultiEditQuery, CompanyMultiEditQueryVariables>(CompanyMultiEditDocument, options);
      }
export function useCompanyMultiEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMultiEditQuery, CompanyMultiEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMultiEditQuery, CompanyMultiEditQueryVariables>(CompanyMultiEditDocument, options);
        }
export type CompanyMultiEditQueryHookResult = ReturnType<typeof useCompanyMultiEditQuery>;
export type CompanyMultiEditLazyQueryHookResult = ReturnType<typeof useCompanyMultiEditLazyQuery>;
export type CompanyMultiEditQueryResult = Apollo.QueryResult<CompanyMultiEditQuery, CompanyMultiEditQueryVariables>;
export const NewCompanyDocument = gql`
    query newCompany($fields: [String!], $model: String!) {
  inlineMedia(fields: $fields) {
    field
    inlineMedia {
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  getSpecificSelect(model: $model) {
    id
    title
    field
  }
  selectCountries {
    id
    title
  }
  selectStates {
    id
    title
  }
  user {
    user {
      id
    }
  }
}
    `;

/**
 * __useNewCompanyQuery__
 *
 * To run a query within a React component, call `useNewCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCompanyQuery({
 *   variables: {
 *      fields: // value for 'fields'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useNewCompanyQuery(baseOptions: Apollo.QueryHookOptions<NewCompanyQuery, NewCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewCompanyQuery, NewCompanyQueryVariables>(NewCompanyDocument, options);
      }
export function useNewCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewCompanyQuery, NewCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewCompanyQuery, NewCompanyQueryVariables>(NewCompanyDocument, options);
        }
export type NewCompanyQueryHookResult = ReturnType<typeof useNewCompanyQuery>;
export type NewCompanyLazyQueryHookResult = ReturnType<typeof useNewCompanyLazyQuery>;
export type NewCompanyQueryResult = Apollo.QueryResult<NewCompanyQuery, NewCompanyQueryVariables>;
export const CompaniesListDocument = gql`
    query CompaniesList($model: String!, $search: String, $pagination: Pagination!, $sorting: Sorting) {
  getDynamicTable(
    model: $model
    search: $search
    pagination: $pagination
    sorting: $sorting
  )
}
    `;

/**
 * __useCompaniesListQuery__
 *
 * To run a query within a React component, call `useCompaniesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesListQuery({
 *   variables: {
 *      model: // value for 'model'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCompaniesListQuery(baseOptions: Apollo.QueryHookOptions<CompaniesListQuery, CompaniesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesListQuery, CompaniesListQueryVariables>(CompaniesListDocument, options);
      }
export function useCompaniesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesListQuery, CompaniesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesListQuery, CompaniesListQueryVariables>(CompaniesListDocument, options);
        }
export type CompaniesListQueryHookResult = ReturnType<typeof useCompaniesListQuery>;
export type CompaniesListLazyQueryHookResult = ReturnType<typeof useCompaniesListLazyQuery>;
export type CompaniesListQueryResult = Apollo.QueryResult<CompaniesListQuery, CompaniesListQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($model: String!, $id: ID!, $data: DataCompany!, $logo: [DataMedia], $removedMedia: [ID], $temp: Boolean) {
  updateCompany(
    model: $model
    id: $id
    data: $data
    logo: $logo
    removedMedia: $removedMedia
    temp: $temp
  ) {
    company {
      id
    }
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      model: // value for 'model'
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      logo: // value for 'logo'
 *      removedMedia: // value for 'removedMedia'
 *      temp: // value for 'temp'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const MultiUpdateCompanyDocument = gql`
    mutation MultiUpdateCompany($model: String!, $ids: [ID]!, $data: DataCompany!, $logo: [DataMedia], $removedMedia: [ID]) {
  multiUpdateCompany(
    model: $model
    ids: $ids
    data: $data
    logo: $logo
    removedMedia: $removedMedia
  ) {
    company {
      id
    }
  }
}
    `;
export type MultiUpdateCompanyMutationFn = Apollo.MutationFunction<MultiUpdateCompanyMutation, MultiUpdateCompanyMutationVariables>;

/**
 * __useMultiUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useMultiUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiUpdateCompanyMutation, { data, loading, error }] = useMultiUpdateCompanyMutation({
 *   variables: {
 *      model: // value for 'model'
 *      ids: // value for 'ids'
 *      data: // value for 'data'
 *      logo: // value for 'logo'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useMultiUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<MultiUpdateCompanyMutation, MultiUpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultiUpdateCompanyMutation, MultiUpdateCompanyMutationVariables>(MultiUpdateCompanyDocument, options);
      }
export type MultiUpdateCompanyMutationHookResult = ReturnType<typeof useMultiUpdateCompanyMutation>;
export type MultiUpdateCompanyMutationResult = Apollo.MutationResult<MultiUpdateCompanyMutation>;
export type MultiUpdateCompanyMutationOptions = Apollo.BaseMutationOptions<MultiUpdateCompanyMutation, MultiUpdateCompanyMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($model: String!, $data: DataCompany!, $user: ID!, $logo: [DataMedia], $removedMedia: [ID], $temp: Boolean) {
  createCompany(
    model: $model
    data: $data
    user: $user
    logo: $logo
    removedMedia: $removedMedia
    temp: $temp
  ) {
    company {
      id
    }
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      model: // value for 'model'
 *      data: // value for 'data'
 *      user: // value for 'user'
 *      logo: // value for 'logo'
 *      removedMedia: // value for 'removedMedia'
 *      temp: // value for 'temp'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const InlineMediaUploadCompanyDocument = gql`
    mutation InlineMediaUploadCompany($id: ID, $model: String!, $field: String!, $images: [Upload]) {
  inlineMediaUpload(id: $id, model: $model, field: $field, images: $images) {
    id
    fileName
    filePath
    thumbnail
    title
    altTitle
    description
    author
  }
}
    `;
export type InlineMediaUploadCompanyMutationFn = Apollo.MutationFunction<InlineMediaUploadCompanyMutation, InlineMediaUploadCompanyMutationVariables>;

/**
 * __useInlineMediaUploadCompanyMutation__
 *
 * To run a mutation, you first call `useInlineMediaUploadCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInlineMediaUploadCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inlineMediaUploadCompanyMutation, { data, loading, error }] = useInlineMediaUploadCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *      field: // value for 'field'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useInlineMediaUploadCompanyMutation(baseOptions?: Apollo.MutationHookOptions<InlineMediaUploadCompanyMutation, InlineMediaUploadCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InlineMediaUploadCompanyMutation, InlineMediaUploadCompanyMutationVariables>(InlineMediaUploadCompanyDocument, options);
      }
export type InlineMediaUploadCompanyMutationHookResult = ReturnType<typeof useInlineMediaUploadCompanyMutation>;
export type InlineMediaUploadCompanyMutationResult = Apollo.MutationResult<InlineMediaUploadCompanyMutation>;
export type InlineMediaUploadCompanyMutationOptions = Apollo.BaseMutationOptions<InlineMediaUploadCompanyMutation, InlineMediaUploadCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    id
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const ResetCompanyDocument = gql`
    mutation ResetCompany($id: ID, $model: String!) {
  resetCompany(id: $id, model: $model)
}
    `;
export type ResetCompanyMutationFn = Apollo.MutationFunction<ResetCompanyMutation, ResetCompanyMutationVariables>;

/**
 * __useResetCompanyMutation__
 *
 * To run a mutation, you first call `useResetCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetCompanyMutation, { data, loading, error }] = useResetCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useResetCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ResetCompanyMutation, ResetCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetCompanyMutation, ResetCompanyMutationVariables>(ResetCompanyDocument, options);
      }
export type ResetCompanyMutationHookResult = ReturnType<typeof useResetCompanyMutation>;
export type ResetCompanyMutationResult = Apollo.MutationResult<ResetCompanyMutation>;
export type ResetCompanyMutationOptions = Apollo.BaseMutationOptions<ResetCompanyMutation, ResetCompanyMutationVariables>;