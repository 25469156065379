import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CategoriesContext} from "./store/categories-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Edit, Add as AddIcon} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);


function Categories() {
    const categoriesCtx = useContext(CategoriesContext);

    // Set table headers data
    const headCells: Array<HeadCell> = categoriesCtx.headers;

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Categories"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={categoriesCtx.search} onSearch={categoriesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item xs={6}>
                    <Typography variant="h3" gutterBottom display="inline">
                        Categories
                    </Typography>
                </Grid>

                <Grid item >
                    <div>
                        {categoriesCtx.selected && categoriesCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={categoriesCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >
                                Multi edit
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={categoriesCtx.addItem}  startIcon={<AddIcon />}>
                            New Category
                        </Button>
                    </div>
                </Grid>

            </Grid>

            {categoriesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }


            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={categoriesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={categoriesCtx.removeItem}
                    onSelectHandler={categoriesCtx.selectItem}
                    onPaginateHandler={categoriesCtx.onPaginate}
                    onSortHandler={categoriesCtx.onSort}
                    count={categoriesCtx.count}
                    page={categoriesCtx.page}
                    rowsPerPage={categoriesCtx.rowsPerPage}
                    filters={categoriesCtx.filters}
                    onMultiEditHandler={categoriesCtx.multiSelected}
                    selectedArray = {categoriesCtx.selected}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Categories;
