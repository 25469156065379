import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import {
    Alert as MuiAlert,
} from "@mui/material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import SnackbarNotification from "../../../components/SnackbarNotification";

// Types
import {dynamicListView} from "../../../types/dynamicListView";
import {Filters} from "../../../types/filters";

// Graphql
import {useCategoriesListQuery} from "../../../graphql/category/category.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {useDeleteCompanyMutation} from "../../../graphql/company/company.graphql-gen";

const Alert = styled(MuiAlert)(spacing);


export const CompaniesContext = React.createContext<dynamicListView>({
    items: [],
    headers: [],
    count: 0,
    addItem: () => {},
    removeItem: (id: string) => {},
    selectItem: (id: string | number) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText:string) => {},
    onSort: (filed:string, order: string) => {},
    page: 0,
    rowsPerPage: 10,
    search: '',
    filters: [],
    multiSelected: (idArray: string[]) => {},
    multiEdit: () => {},
    selected: [],
});

const CompaniesContextProvider: React.FC = (props) => {
    const model: string = 'Company';

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [id, setId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: 'title', order: 'asc'});
    const [filters, setFilters] = useState<Filters[]>([]);
    const [slectedArray, setSelectedArray] = useState<any[]>([]);

    // Get data from API (queries)
    const {data, error, loading, refetch: refetchListViewData} = useCategoriesListQuery({
        variables: {
            model,
            search,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            }
        }, onCompleted: () => {
            if (data) {
                setHeaders(data.getDynamicTable.tableHeader);
                setItems(data.getDynamicTable.tableData);
                setCurrentPage(data.getDynamicTable.page);
                setItemsPerPage(data.getDynamicTable.limit);
                setCount(data.getDynamicTable.count);
                setSearch(data.getDynamicTable.search);
                setFilters(data.getDynamicTable.filters);
            }
        }
    });
    const [deleteCompany] = useDeleteCompanyMutation();


    if (loading) {
        return <Loader/>;
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------


    // Handle add
    const addItemHandler = () => {
        navigate(`/companies/new`, {replace: true});
    };

    // Handel delete
    const removeItemHandler = (id: string) => {
        if (id) {
            setId(id);
        }
        setOpen(true);
    }

    // Go to singe view
    const selectItemHandler = (id: string | number) => {
        navigate(`/companies/${id}`, {replace: true});
    };

    // Delete mutation
    const deleteCompanyMutation = (id: string) => {
        setOpen(false);

        deleteCompany({
            variables: {
                id
            }
        }).then((res: any) => {
            refetchListViewData();
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            model,
            search,
            pagination: {
                limit: limit,
                page: page
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });

        setItemsPerPage(limit);
        setCurrentPage(page);
    };


    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            model,
            search: searchText,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            model,
            search,
            pagination: {
                limit: itemsPerPage,
                page: 0
            },
            sorting: {
                field,
                order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // multiselect
    const multiSelectedHendler = (idArray: string[]) => {
        setSelectedArray(idArray)
    }
    const multiEditHendler = () => {
        navigate(`/companies/multi-edit`, {replace: true, state:{ids:slectedArray}});
    }

    // Set context values
    const contextValue: dynamicListView = {
        items: items,
        headers: headers,
        count: count,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        selectItem: selectItemHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        filters: filters,
        multiEdit: multiEditHendler,
        multiSelected: multiSelectedHendler,
        selected: slectedArray
    };

    return (
        <CompaniesContext.Provider value={contextValue}>
            {props.children}

            <ConfirmationDialog
                message="Are you sure you want to remove the item? This action cannot be undone!"
                title="Delete"
                button="Delete"
                open={open}
                onConfirm={() => deleteCompanyMutation(id)}
                onCancel={closeDialog}/>
        </CompaniesContext.Provider>
    );
};

export default CompaniesContextProvider;
