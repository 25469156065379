import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import {
    Alert as MuiAlert,
} from "@mui/material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

// Types
import {dynamicListView} from "../../../types/dynamicListView";

// Graphql
import {
    useMediacenterQuery,
    useDeleteFolderMutation,
    useDeleteFileMutation,
    useCreateFileMutation, useCreateReferenceMutation, CreateFileMutation, CreateReferenceMutation
} from "../../../graphql/mediacenter/mediacenter.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Filters} from "../../../types/filters";
import SnackbarNotification from "../../../components/SnackbarNotification";
import {FetchResult} from "@apollo/client";


const Alert = styled(MuiAlert)(spacing);


export const MediacenterContext = React.createContext<dynamicListView>({
    items: [],
    headers: [],
    count: 0,
    addFolder: () => {},
    backFolder: () => {},
    addItem: () => {},
    removeItem: (id: string, type?: string) => {},
    selectItem: (id: string | number) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText: string) => {},
    onSort: (filed: string, order: string) => {},
    page: 0,
    rowsPerPage: 10,
    search: '',
    filters: [],
    multiSelected: (idArray: string[]) => {},
    multiEdit: () => {},
    selected: [],
    folderTitle: '',
    addToModel: () => {},
    backToModel: () => {}
});

const MediacenterContextProvider: React.FC = (props) => {

    const params = useParams();
    const idFolder = params.id ? params.id : '';
    const location: any = useLocation();

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [id, setId] = useState<string>('');
    const [type, setType] = useState<any>('');
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: 'title', order: 'asc'});
    const [filters, setFilters] = useState<Filters[]>([]);
    const [slectedArray, setSelectedArray] = useState<any[]>([]);
    const [folderTitle, setFolderTitle] = useState<string>('');
    const [parentId, setParentId] = useState<string>('');


    // Get data from API (queries)
    const {data, error, loading, refetch: refetchListViewData} = useMediacenterQuery({
        variables: {
            search,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            id: idFolder,
            dataModel:location.state
        },
        //skip: idFolder !== '',
        onCompleted: () => {
            if (data) {
                setHeaders(data.getMediacenter.tableHeader);
                setItems(data.getMediacenter.tableData);
                setCurrentPage(data.getMediacenter.page);
                setItemsPerPage(data.getMediacenter.limit);
                setCount(data.getMediacenter.count);
                setSearch(data.getMediacenter.search);
                setFolderTitle(data.getMediacenter.folderTitle);
                setParentId(data.getMediacenter.folderParent);
                //setFilters(data.getMediacenter.filters);
            }
        }
    });


    const [deleteFolder] = useDeleteFolderMutation();
    const [deleteFile] = useDeleteFileMutation();
    const [createReference] = useCreateReferenceMutation();


    if (loading) {
        return <Loader/>;
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------


    // Handle add
    const addFolderHandler = () => {
        navigate(`/mediacenter/folder/new`, {replace: true, state:{id: idFolder, fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
    };
    const addItemHandler = () => {
        navigate(`/mediacenter/file/new`, {replace: true, state:{id: idFolder, fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
    };

    //Back to parent folder
    const backFolderHandler = () => {
        if(parentId){
            navigate(`/mediacenter/folder/${parentId}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
        }else{
            navigate(`/mediacenter/`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
        }
    };

    // Handel delete
    const removeItemHandler = (id: string, type:any) => {
        if (id) {
            setId(id);
        }
        if (type){
            setType(type);
        }
        setOpen(true);
    }

    // Go to singe view
    const selectItemHandler = (id: string | number) => {
        navigate(`/mediacenter/file/${id}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
    };

    const selectFolderHandler = (id: string | number) => {
        navigate(`/mediacenter/folder/${id}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
    };

    // Delete mutation
    const deleteMutation = (id: string, type: string) => {
        setOpen(false);

        if(type=='folder'){
            deleteFolder({
                variables: {
                    id
                }
            }).then((res: any) => {
                refetchListViewData();
            }).catch(error => {
                return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
        }else{
            deleteFile({
                variables: {
                    id
                }
            }).then((res: any) => {
                refetchListViewData();
            }).catch(error => {
                return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
        }

    };



    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            search,
            pagination: {
                limit: limit,
                page: page
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });

        setItemsPerPage(limit);
        setCurrentPage(page);
    };


    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            search: searchText,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            search,
            pagination: {
                limit: itemsPerPage,
                page: 0
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // multiselect
    const multiSelectedHendler = (idArray: string[]) => {
        setSelectedArray(idArray)
    }
    const multiEditHendler = () => {
        navigate(`/mediacenter/multi-edit`, {replace: true, state: {ids: slectedArray, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}});
    }

    //add to file to model
    const addToModelHandler = () => {

        createReference({
            variables: {
                files: slectedArray,
                dataModel: location.state,
            },
        })
            .then((res: FetchResult<CreateReferenceMutation>) => {
                if(location.state?.modelIds){
                    navigate(`/${res.data?.createReference.url}/multi-edit`, {replace: true, state:{ids:location.state?.modelIds}});
                }else{
                    navigate(`/${res.data?.createReference.url}/${res.data?.createReference.id}`, {replace: true, state: {fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
                }

            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });

    };

    //back to model
    const backToModelHandler = () => {
        switch (location.state?.modelName) {
            case 'Approval':
                location.state.modelName = 'settings/approvals';
                break;
            case 'Category':
                location.state.modelName = 'categories';
                break;
            case 'Product':
                location.state.modelName = 'products';
                break;
            case 'Company':
                location.state.modelName = 'companies';
                break;

        }
        if(location.state?.modelIds){
            navigate(`/${location.state.modelName}/multi-edit`, {replace: true, state:{ids:location.state?.modelIds}});
        }else{
            navigate(`/${location.state.modelName}/${location.state?.modelId}`, {replace: true});
        }

    }

    // Set context values
    const contextValue: dynamicListView = {
        items: items,
        headers: headers,
        count: count,
        addFolder: addFolderHandler,
        backFolder: backFolderHandler,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        selectFolder: selectFolderHandler,
        selectItem: selectItemHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        filters: filters,
        multiEdit: multiEditHendler,
        multiSelected: multiSelectedHendler,
        selected: slectedArray,
        folderTitle: folderTitle,
        addToModel: addToModelHandler,
        backToModel: backToModelHandler
    };

    return (
        <MediacenterContext.Provider value={contextValue}>
            {props.children}

            <ConfirmationDialog
                message="Are you sure you want to remove the item? This action cannot be undone!"
                title="Delete"
                button="Delete"
                open={open}
                onConfirm={() => deleteMutation(id, type)}
                onCancel={closeDialog}/>
        </MediacenterContext.Provider>
    );
};

export default MediacenterContextProvider;
