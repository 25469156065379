import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {UsersContext} from "./store/users-context";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon} from "@mui/icons-material";
import SearchBar from "../../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);


function Users() {
    const usersCtx = useContext(UsersContext);

    // Set table headers data
    const headCells: Array<HeadCell> = usersCtx.headers;

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Users"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={usersCtx.search} onSearch={usersCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Users
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" onClick={usersCtx.addItem}>
                            <AddIcon/>
                            New User
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {usersCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={usersCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={usersCtx.removeItem}
                    onSelectHandler={usersCtx.selectItem}
                    onPaginateHandler={usersCtx.onPaginate}
                    onSortHandler={usersCtx.onSort}
                    count={usersCtx.count}
                    page={usersCtx.page}
                    rowsPerPage={usersCtx.rowsPerPage}
                    filters={usersCtx.filters}/>
            </Grid>
        </React.Fragment>
    );
}

export default Users;
