import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormControlLabel, FormGroup,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const platforms: { id: string, title: string }[] = data.selectPlatforms;

    // States
    const [selectedPlatforms, setSelectedPlatforms] = useState<any>(state.platforms ? state.platforms.map((id:string) => platforms.filter((item) => item.id === id)[0]) : (values.platforms ? values.platforms.map((id:string) => platforms.filter((item) => item.id === id)[0]) : []));
    const [description, setDescription] = useState(state.description ? state.description : (values.description ? values.description : ''));


    //------------------------------- Public methods -------------------------------------


    // Update description on change
    const handleChangeDescription = (event: any) => {
        event.toString('html');
        setDescription(event);
    };

    const handlePlatformChange = (event:any, value:any) =>{
        setSelectedPlatforms(value)
    }

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>

                        <TextField
                            name="title"
                            label = {multiEdit ? 'Title' : 'Title *'}
                            value={values.title ? values.title : ''}
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            onChange={onChange}
                            variant="outlined"
                        />

                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"

                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSorting"
                                    checked={state.clearSorting ? state.clearSorting : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitle"
                        label="Subtitle"
                        value={values.subtitle ? values.subtitle : ''}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitle"
                                    checked={state.clearSubtitle ? state.clearSubtitle : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={platforms}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title ? option.title : ''}
                        defaultValue={selectedPlatforms || []}
                        value={selectedPlatforms || []}
                        onChange={(event:any, value:any) => {handlePlatformChange(event, value);  onChange(event, 'platforms', 'autocomplete', value.map((item:any) => item.id))}}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Platforms"  placeholder="Platforms" name="platforms"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPlatforms"
                                    checked={state.clearPlatforms ? state.clearPlatforms : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>


                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Short description"
                        name="shortDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescription ? values.shortDescription : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearShortDescription"
                                    checked={state.clearShortDescription ? state.clearShortDescription : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Description</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={(e) => {
                                handleChangeDescription(e);
                                onChange(e, 'description')
                            }}
                        />
                    </QuillWrapper>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescription"
                                    checked={state.clearDescription ? state.clearDescription : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoCategory;
